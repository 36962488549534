<footer>
  <a href="/" class="logo">
    <img src="images/sekreterare_logo.png" alt="logo" />
  </a>

  <div class="copy">
    <a href="https://straycat.se">© 2022 Stray Cat AB</a>
  </div>
</footer>

<style lang="scss">
  .logo {
    margin: 0 auto;
    display: block;
    width: max-content;
    padding: 40px 0;
  }

  .copy {
    text-align: center;
    padding: 14px;
    border-top: 1px solid rgba(146, 150, 160, 0.3);
  }
</style>
